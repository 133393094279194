import React, { PureComponent } from 'react';
import { Col } from 'reactstrap';
import PropTypes from 'prop-types';
import WizardFormOne from './StepOne/WizardFormOne';
import WizardFormTwo from './StepTwo/WizardFormTwo';
import WizardFormThree from './StepThree/WizardFormThree';
import ModalConfirm from '../../../shared/components/modals/ModalConfirm';
import Modal from '../../../shared/components/modals/Modal';

export default class WizardForm extends PureComponent {
  static propTypes = {
    prospects: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      investor: PropTypes.shape({
        id: PropTypes.number,
        contactInformation: PropTypes.shape({
          id: PropTypes.number,
          fullName: PropTypes.string,
          picture: PropTypes.shape({
            id: PropTypes.number,
            pictureName: PropTypes.string,
          }),
        }),
      }),
      investorConnections: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        error: PropTypes.bool,
        name: PropTypes.string,
        linkedinUrl: PropTypes.string,
        selected: PropTypes.bool,
        picture: PropTypes.shape({
          id: PropTypes.number,
          linkedinIntroPictureName: PropTypes.string,
        }),
      })),
      searched: PropTypes.bool,
      introduced: PropTypes.bool,
    })).isRequired,
    getInvestorProspects: PropTypes.func.isRequired,
    showNotifSuccess: PropTypes.func.isRequired,
    showNotifError: PropTypes.func.isRequired,
    actionLeft: PropTypes.bool,
  };

  static defaultProps = {
    actionLeft: null,
  }

  constructor(props) {
    super(props);
    this.state = {
      step: null,
      page: null,
      modalConfirm: false,
      modalUpgrade: false,
      messages: [],
      improvements: 0,
    };
  }

  componentDidMount() {
    const { prospects, actionLeft } = this.props;
    const user = JSON.parse(localStorage.getItem('user'));

    if (user && user.plugin && prospects.length && !prospects.some(prospect => !prospect.introduced)
      && !prospects.some(prospect => !prospect.searched) && !actionLeft) {
      this.setState({ step: 2, page: 3 });
    } else if (user && prospects.length && (prospects.some(prospect => !prospect.searched)
      || prospects.some(prospect => !prospect.introduced) || !user.plugin || actionLeft)) {
      this.setState({ step: 1, page: 2 });
    } else {
      this.setState({ step: 0, page: 1 });
    }
  }

  nextPage = () => {
    const { step, page, modalConfirm } = this.state;
    const pages = page + 1;

    this.setState({ step: step + 1, page: pages }, () => {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 200);
    });
    if (modalConfirm) {
      this.setState({ modalConfirm: false });
    }
  };

  previousPage = () => {
    const { campaignId } = this.state;
    this.setState(prevState => ({ page: prevState.page - 1, campaignId }));
  };

  goToStep = (pageIndex) => {
    const { page } = this.state;
    if (page !== pageIndex) {
      this.setState({ page: pageIndex });
    }
  }

  replaceToCamelCase = (data, string) => {
    const regex = new RegExp(string, 'i');
    return data.replaceAll(
      data.match(regex)[0], string,
    );
  };

  toggleModalConfirm = () => {
    this.setState({ modalConfirm: false });
  }

  toggleModalUpgrade = () => {
    this.setState({ modalUpgrade: false });
  }

  render() {
    const {
      prospects, getInvestorProspects, showNotifError, showNotifSuccess, actionLeft,
    } = this.props;
    const {
      page, modalConfirm, messages, improvements, modalUpgrade,
    } = this.state;

    return (
      <Col md={12} lg={12}>
        <div className="wizard">
          <div className="wizard__steps wizard__extra-margin">
            <button
              type="button"
              className={`wizard__step${page === 1 ? ' wizard__step--active' : ''} wizard__step--clickable`}
              onClick={() => { this.goToStep(1); }}
              onKeyUp={(e) => {
                if ((e.keyCode === 13 || e.keyCode === 32)) { this.goToStep(1); }
              }}
            >
              <span className="wizard_step_counter">01</span>
              <p>Select Investors</p>
            </button>
            <svg
              className="wizard__steps_divider"
              width="549"
              height="2"
              viewBox="0 0 549 2"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 1H549" stroke="#8D9AA1" />
            </svg>
            <button
              type="button"
              className={`wizard__step${page === 2 ? ' wizard__step--active' : ''}
              ${prospects && prospects.length > 0 ? ' wizard__step--clickable' : ''}`}
              onClick={() => {
                if (prospects && prospects.length > 0) {
                  this.goToStep(2);
                }
              }}
              onKeyUp={(e) => {
                if ((e.keyCode === 13 || e.keyCode === 32) && prospects && prospects.length > 0) {
                  this.goToStep(2);
                }
              }}
            >
              <span className="wizard_step_counter">02</span>
              <p>Warm Introductions</p>
            </button>
            <svg
              className="wizard__steps_divider"
              width="549"
              height="2"
              viewBox="0 0 549 2"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 1H549" stroke="#8D9AA1" />
            </svg>
            <button
              type="button"
              className={`wizard__step${page === 3 ? ' wizard__step--active' : ''}
              ${prospects && prospects.some(prospect => prospect.introduced === true)
                ? ' wizard__step--clickable' : ''}`}
              onClick={() => {
                if (prospects && prospects.some(prospect => prospect.introduced === true)) {
                  this.goToStep(3);
                }
              }}
              onKeyUp={(e) => {
                if ((e.keyCode === 13 || e.keyCode === 32) && prospects
                  && prospects.some(prospect => prospect.introduced === true)) {
                  this.goToStep(3);
                }
              }}
            >
              <span className="wizard_step_counter">03</span>
              <p>Review Progress</p>
            </button>
          </div>
          {page === 1
          && (
            <WizardFormOne
              nextStep={this.nextPage}
              getInvestorProspects={getInvestorProspects}
            />
          )}
          {page === 2
          && (
            <WizardFormTwo
              previousPage={this.previousPage}
              nextPage={this.nextPage}
              prospects={prospects}
              editTemplates={this.editTemplates}
              showNotifSuccess={showNotifSuccess}
              showNotifError={showNotifError}
              getInvestorProspects={getInvestorProspects}
              actionLeft={actionLeft}
            />
          )}
          {page === 3
          && (
            <WizardFormThree
              previousPage={this.previousPage}
              prospects={prospects}
              showNotifError={showNotifError}
            />
          )}
        </div>
        <ModalConfirm
          messages={messages}
          improvements={improvements}
          onSuccess={this.nextPage}
          displayed={modalConfirm}
          toggleModal={this.toggleModalConfirm}
        />
        <Modal
          color="primary"
          title="Upgrade Required!"
          colored
          displayed={modalUpgrade}
          toggleModal={() => this.toggleModalUpgrade()}
          message={`Please upgrade your account to a paid plan if you wish to create more campaigns.
           You can upgrade on our <a href='${process.env.REACT_APP_DOMAIN_URL}/pricing' target="_blank">
           Pricing Page</a>.`}
        />
      </Col>
    );
  }
}
