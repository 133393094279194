import React, { PureComponent } from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';
import axios from 'axios';
import StatElements from '../StatElements';
import DashboardElements from './DashboardElements';

class WizardFormThree extends PureComponent {
  static propTypes = {
    showNotifError: PropTypes.func.isRequired,
    previousPage: PropTypes.func.isRequired,
    prospects: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      investorConnections: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        selected: PropTypes.bool.isRequired,
        linkedinUrl: PropTypes.string.isRequired,
        picture: PropTypes.string,
      })),
      searched: PropTypes.bool,
      introduced: PropTypes.bool,
    })).isRequired,
  }

  constructor() {
    super();
    this.state = {
      dashboard: null,
      loading: true,
    };
  }

  componentDidMount() {
    const { showNotifError } = this.props;
    axios.get('/introductions/dashboard')
      .then((response) => {
        if (response.data && response.data.success) {
          this.setState({ dashboard: response.data.dashboard, loading: false });
        } else {
          showNotifError('Sorry, an error occurred, please try again.');
        }
      })
      .catch((error) => {
        if (error && error.response) {
          showNotifError(error.response.data.error || 'Sorry, an error occurred, please try again.');
        }
        showNotifError('Sorry, an error occurred, please try again.');
      });
  }

  render() {
    const { prospects, previousPage } = this.props;
    const { dashboard, loading } = this.state;

    return (
      <div
        className="wizard__form mt-1 pt-1"
      >
        <StatElements prospects={prospects} />
        {loading
          ? (
            <div className={`load${loading ? '' : ' loaded'} inload`}>
              <div className="load__icon-wrap">
                <svg className="load__icon">
                  <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                </svg>
              </div>
            </div>
          ) : (
            <DashboardElements
              data={dashboard}
            />
          )}
        <Button
          color="primary"
          type="button"
          className="previous btn btn-primary icon rounded float-left"
          onClick={previousPage}
        >
          <p>
            <ChevronLeftIcon />
            BACK
          </p>
        </Button>
      </div>
    );
  }
}

export default WizardFormThree;
