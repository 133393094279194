import {
  Button, Col, Row,
} from 'reactstrap';
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import TextField from '@material-ui/core/TextField';
import queryString from 'query-string';
import StatElements from '../StatElements';
import InvestorProspectsList from './InvestorProspectsList';
import ButtonsElements from './ButtonsElements';
import ConnectionsList from './ConnectionsList';
import Modal from './Modal';

const renderTextField = ({
  input, label, meta: { touched, error }, children, type, placeholder, multiline,
}) => (
  <div className="form__form-group-input-wrap">
    <TextField
      className="material-form__field template-content"
      label={label}
      type={type}
      error={touched && error}
      helperText={touched && error} // This will show the error message
      value={input.value}
      placeholder={placeholder}
      multiline={multiline}
      rows={2}
      rowsMax={5}
      onChange={(e) => {
        e.preventDefault();
        input.onChange(e.target.value);
      }}
    >
      {children}
    </TextField>
  </div>
);

renderTextField.propTypes = {
  input: PropTypes.shape().isRequired,
  label: PropTypes.string,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }),
  children: PropTypes.arrayOf(PropTypes.element),
  type: PropTypes.string,
  placeholder: PropTypes.string,
  multiline: PropTypes.bool,
};

renderTextField.defaultProps = {
  meta: null,
  label: '',
  children: [],
  type: 'text',
  placeholder: '',
  multiline: false,
};

class SelectConnections extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      initialValues: null,
      // eslint-disable-next-line react/no-unused-state
      connectionInitialValues: null,
      selectedProspect: null,
      selectedConnection: null,
      filteredProspects: [],
      prospectsContacted: [],
      connectionsToContact: [],
      templatesToSave: [],
      messages: null,
      loading: true,
      modal: false,
    };
  }

  componentDidMount() {
    this.initMessages();
  }

  componentDidUpdate(prevProps) {
    const { prospects } = this.props;

    if (prevProps.prospects !== prospects) {
      this.initProspects();
    }
  }

  initMessages = () => {
    const { showNotifError, toggleTemplates } = this.props;

    axios.get('/introductions/getMessages')
      .then((response) => {
        if (response.data && response.data.success) {
          this.setState({ messages: response.data.templates }, () => this.initProspects());
        } else {
          toggleTemplates();
        }
      })
      .catch((error) => {
        if (error && error.response) {
          // eslint-disable-next-line max-len
          showNotifError(error.response.data.error || 'Sorry, an error occurred, please try again or contact support');
        }
        showNotifError('Sorry, an error occurred, please try again or contact support');
      });
  }

  // Function to select initial prospect & connection
  initProspects = () => {
    const { prospects, getInvestorProspects } = this.props;
    const { prospectsContacted } = this.state;

    if (prospects) {
      // Filter Prospects that are searched but not introduced so we find intro path to these investors/prospects
      const filteredProspects = prospects.filter(prospect => (
        prospect.searched && prospect.investorConnections.length > 0
        && !prospectsContacted.includes(prospect.id) && !prospect.introduced
      ));

      if (filteredProspects.length > 0) {
        // This is the prospect/investor we are currently viewing
        const prospectSelected = { ...filteredProspects[0] };

        // This is the connection we are currently reviewing
        const connectionSelected = {
          ...prospectSelected.investorConnections.find(connection => connection.contacted === false),
        };

        this.setState({ filteredProspects });
        this.onProspectChange(prospectSelected, connectionSelected);
      } else {
        getInvestorProspects(1);
      }
    }

    this.setState({
      loading: false,
    });
  }

  askHowManyConnections = () => {
    this.setState({ modal: true });
  }

  toggleModal = () => {
    const { modal } = this.state;
    this.setState({ modal: !modal });
  };

  selectRandomConnections = (quantity) => {
    const {
      // eslint-disable-next-line no-unused-vars
      getInvestorProspects, showNotifSuccess, showNotifError, nextPage,
    } = this.props;

    this.setState({ modal: false });

    axios.post('/introductions/selectRandomConnections', queryString.stringify({ quantity }))
      .then((response) => {
        if (response.data && response.data.success) {
          showNotifSuccess(`We selected ${quantity} connection for every investor you selected.`);
          nextPage();
          getInvestorProspects(true);
        } else {
          showNotifError('Sorry, an error occurred, please try again.');
        }
      })
      .catch((error) => {
        if (error && error.response) {
          showNotifError(error.response.data.error || 'Sorry, an error occurred, please try again.');
        }
        showNotifError('Sorry, an error occurred, please try again.');
      });
  }

  updateTemplates = (templates) => {
    let updatedTemplatesToSave = [];
    const { connectionsToContact, templatesToSave, selectedConnection } = this.state;
    const updatedTemplates = { ...templates, connectionId: selectedConnection.id };
    const isTemplateAdded = templatesToSave.some(item => item.connectionId === selectedConnection.id);

    if (connectionsToContact.includes(selectedConnection.id) && isTemplateAdded) {
      // If we already have the templates saved, update it
      updatedTemplatesToSave = templatesToSave.filter(item => item.connectionId !== selectedConnection.id);
      // then re-add it
      updatedTemplatesToSave = [...updatedTemplatesToSave, updatedTemplates];
    } else if (connectionsToContact.includes(selectedConnection.id) && !isTemplateAdded) {
      // If we don't have the template then add it
      updatedTemplatesToSave = [...templatesToSave, updatedTemplates];
    } else if (isTemplateAdded) {
      // remove it from the templates
      updatedTemplatesToSave = templatesToSave.filter(item => item.connectionId !== selectedConnection.id);
    }

    this.setState({ templatesToSave: updatedTemplatesToSave });
  }

  onProspectChange = (prospect, connection) => {
    if (prospect) {
      this.setState({
        templatesToSave: [], connectionsToContact: [], selectedProspect: prospect, loading: true,
      }, () => {
        this.onConnectionChange(connection, true);
      });
    }
  }

  onConnectionChange = (connection, addConnectionToContact) => {
    const { messages, selectedProspect, connectionsToContact } = this.state;
    let updatedConnectionsToContact = [];

    // check if this connection already is in the list of prospects To Contact
    if (addConnectionToContact) {
      if (connectionsToContact.includes(connection.id)) {
        updatedConnectionsToContact = connectionsToContact.filter(item => item !== connection.id);
      } else {
        // otherwise add it
        updatedConnectionsToContact = [...connectionsToContact, connection.id];
      }
    }

    if (messages && Object.keys(messages).length && connection && selectedProspect) {
      const filteredValue = messages[selectedProspect.id][connection.id];
      this.setState({ loading: false, initialValues: filteredValue });
    }
    this.setState({ selectedConnection: connection, connectionsToContact: updatedConnectionsToContact });
  }

  askWarmIntro = () => {
    const {
      selectedProspect, prospectsContacted, templatesToSave,
    } = this.state;
    const {
      getInvestorProspects, showNotifSuccess, showNotifError, prospects, nextPage,
    } = this.props;

    axios.post('introductions/askWarmIntro', queryString.stringify({
      templates: JSON.stringify(templatesToSave),
      prospect: selectedProspect.id,
    })).then((response) => {
      if (response.data && response.data.success) {
        showNotifSuccess('Your messages have been queued.');
        if (prospects && prospects.filter(p => p.searched && !p.introduced).length === 0) {
          nextPage();
        } else {
          prospectsContacted.push(selectedProspect.id);
          this.setState({ prospectsContacted });
        }
        getInvestorProspects(true);
        this.initMessages();
      } else {
        showNotifError('Sorry, an error occurred, please try again.');
      }
    }).catch((error) => {
      if (error && error.response) {
        showNotifError(error.response.data.error || 'Sorry, an error occurred, please try again.');
      }
      showNotifError('Sorry, an error occurred, please try again.');
    });
  }

  render = () => {
    const {
      prospects, previousPage, toggleTemplates,
    } = this.props;
    const {
      width, filteredProspects, selectedProspect, selectedConnection,
      initialValues, loading, connectionsToContact, modal,
    } = this.state;

    return (
      <div
        className={`tabs container pr-0 pl-0 ${width >= 800
          ? 'tabs--vertical mt-5' : 'tabs--horizontal tabs--bordered-bottom'}`}
      >
        <StatElements prospects={prospects} />
        <ButtonsElements
          askHowManyConnections={() => this.askHowManyConnections()}
          toggleTemplates={() => toggleTemplates()}
        />
        {filteredProspects && selectedConnection && selectedProspect ? (
          <Row className="mt-4 ml-0 mr-0">
            <Col xs={12} sm={12} md={5} xl={3}>
              <InvestorProspectsList
                prospectsList={filteredProspects}
                selectedProspect={selectedProspect}
                toggleProspect={(prospect) => {
                  this.onProspectChange(
                    prospect, prospect.investorConnections.find(connection => connection.contacted === false),
                  );
                }}
              />
            </Col>
            <Col xs={12} sm={12} md={7} xl={9}>
              {loading ? (
                // We put this loader here in case we need to refresh to form
                <div className={`load${loading ? '' : ' loaded'} inload`}>
                  <div className="load__icon-wrap">
                    <svg className="load__icon">
                      <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                    </svg>
                  </div>
                </div>
              ) : (
                <ConnectionsList
                  selectedConnectionId={selectedConnection.id}
                  selectedProspect={selectedProspect}
                  connectionsToContact={connectionsToContact}
                  selectConnection={connection => this.onConnectionChange(connection, true)}
                  initialValues={initialValues}
                  updateTemplates={this.updateTemplates}
                  handleSubmit={this.askWarmIntro}
                />
              )}
            </Col>
          </Row>
        ) : (
          <Row className="mt-4 ml-0 mr-0">
            {loading ? (
              <div className={`load${loading ? '' : ' loaded'} inload`}>
                <div className="load__icon-wrap">
                  <svg className="load__icon">
                    <path fill="#4ce1b6" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
                  </svg>
                </div>
              </div>
            ) : (
              <Col xs={12} sm={12} md={12} xl={12}>
                <div className="w-100 text-center p-5">
                  <h1>We could not find investors to review.</h1>
                  <p>
                    Please contact support.
                  </p>
                </div>
              </Col>
            )}
          </Row>
        )}
        <Button
          color="primary"
          type="button"
          className="icon rounded icon--left float-left ml-5 select-more-investor mt-5"
          onClick={previousPage}
        >
          <p>
            <ChevronLeftIcon />
            Select More Investors
          </p>
        </Button>
        <Modal
          toggleModal={this.toggleModal}
          displayed={modal}
          submit={quantity => this.selectRandomConnections(quantity)}
        />
      </div>
    );
  }
}

SelectConnections.propTypes = {
  previousPage: PropTypes.func.isRequired,
  prospects: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    investor: PropTypes.shape({
      id: PropTypes.number,
      contactInformation: PropTypes.shape({
        id: PropTypes.number,
        fullName: PropTypes.string,
        picture: PropTypes.shape({
          id: PropTypes.number,
          pictureName: PropTypes.string,
        }),
      }),
    }),
    investorConnections: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      error: PropTypes.bool,
      linkedinUrl: PropTypes.string,
      selected: PropTypes.bool,
      name: PropTypes.string,
      picture: PropTypes.shape({
        id: PropTypes.number,
        linkedinIntroPictureName: PropTypes.string,
      }),
    })),
    searched: PropTypes.bool,
    introduced: PropTypes.bool,
  })).isRequired,
  toggleTemplates: PropTypes.func.isRequired,
  showNotifSuccess: PropTypes.func.isRequired,
  showNotifError: PropTypes.func.isRequired,
  getInvestorProspects: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
};


export default SelectConnections;
