import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'reactstrap';
import NotificationSystem from 'rc-notification';

import SearchElement from './SearchElement';
import ColumnsElement from './ColumnsElement';
import AccountWarnings from '../../../../shared/components/AccountWarnings';
import {
  BasicNotification,
  FullWideNotification,
  LinkNotification,
} from '../../../../shared/components/notifications/Notification';

let notificationTC = null;
let notificationRU = null;

const showNotification = ({
  notification,
  position,
}, rtl) => {
  switch (position) {
    case 'right-up':
      notificationRU.notice({
        content: notification,
        duration: 30,
        closable: true,
        style: {
          top: 30,
          left: 'calc(100vw - 100%)',
        },
        className: `${position} ${rtl}-support`,
      });
      break;
    default:
      notificationTC.notice({
        content: notification,
        duration: 15,
        closable: true,
        style: {
          top: 0,
          left: 0,
        },
        className: `${position} ${rtl}-support`,
      });
      break;
  }
};

class DashboardElements extends PureComponent {
  static propTypes = {
    data: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      messages: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string,
        content: PropTypes.string,
        send: PropTypes.bool,
        sent: PropTypes.bool,
        sentOn: PropTypes.object,
        position: PropTypes.number,
        delay: PropTypes.number,
        investor: PropTypes.shape({
          id: PropTypes.number.isRequired,
          fullName: PropTypes.string.isRequired,
        }),
        investorConnections: PropTypes.shape({
          id: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
          selected: PropTypes.bool.isRequired,
          linkedinUrl: PropTypes.string.isRequired,
          picture: PropTypes.shape({
            id: PropTypes.number.isRequired,
            pictureName: PropTypes.string.isRequired,
            pictureSize: PropTypes.number.isRequired,
          }),
        }),
      })),
    }),
    modal: PropTypes.bool,
  };

  static defaultProps = {
    data: null,
    modal: false,
  };

  state = {
    list: [],
    recipients: null,
    // hasRecipients: false,
    loading: true,
    stats: {
      scheduled: {
        total: 0,
      },
      contacted: {
        total: 0,
        rate: 0,
      },
      followups: [],
      opened: {
        total: 0,
        rate: 0,
      },
      visited: {
        total: 0,
        rate: 0,
      },
    },
  }

  componentDidMount = () => {
    const { data } = this.props;
    if (data) {
      this.setState({ list: data });
      this.sortDataByColumn(data);
    } else {
      this.setState({ loading: false });
    }

    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({ style: { top: 75 } }, n => notificationTC = n);
    // eslint-disable-next-line no-return-assign
    NotificationSystem.newInstance({ style: { top: 75 } }, n => notificationRU = n);
  }

  componentWillUnmount() {
    if (notificationTC) {
      notificationTC.destroy();
    }
    if (notificationRU) {
      notificationRU.destroy();
    }
  }

  sortDataByColumn = (data) => {
    const recipients = {
      scheduled: [],
      contacted: [],
      completed: [],
      followups: [],
      replied: [],
    };
    const stats = {
      scheduled: {
        total: 0,
      },
      completed: {
        total: 0,
      },
      contacted: {
        total: 0,
        rate: 0,
      },
      followups: [],
      replied: {
        total: 0,
        rate: 0,
      },
    };

    for (let i = 0; i < data.length; i += 1) {
      let completed = 0;
      stats.scheduled.total += 1;

      if (data[i].toSend === 0) {
        // completed
        recipients.completed.push(data[i]);
        stats.completed.total += 1;
        completed = 1;
      }
      if (data[i].sent === 0) {
        // scheduled
        if (!completed) {
          recipients.scheduled.push(data[i]);
        }
      } else {
        stats.contacted.total += 1;

        if (typeof stats.followups[data[i].sent - 2] === typeof undefined && data[i].sent - 2 >= 0) {
          stats.followups[data[i].sent - 2] = {
            total: 0,
            rate: 0,
            left: 0,
          };
        }

        // follow ups
        if (!completed) {
          if (typeof recipients.followups[data[i].sent - 1] === typeof undefined) {
            recipients.followups[data[i].sent - 1] = [];
          }
          recipients.followups[data[i].sent - 1].push(data[i]);
        }

        if (data[i].sent > 1) {
          // followups
          let x = 2;
          while (data[i].sent - x >= 0) {
            if (typeof stats.followups[data[i].sent - x] === typeof undefined && data[i].sent - x >= 0) {
              stats.followups[data[i].sent - x] = {
                total: 0,
                rate: 0,
                left: 0,
              };
            }
            stats.followups[data[i].sent - x].total += 1;
            x += 1;
          }
          if (data[i].toSend > 0 && data[i].sent - 2 >= 0) {
            stats.followups[data[i].sent - 2].left += 1;
          }
        }

        if (data[i].replied >= 1) {
          // replied
          recipients.replied.push(data[i]);
          stats.replied.total += 1;
        }
      }
    }

    stats.contacted.rate = stats.scheduled.total > 0
      ? Number((stats.contacted.total * 100 / stats.scheduled.total).toFixed(0))
      : 0;
    stats.replied.rate = stats.contacted.total
      ? Number((stats.replied.total * 100 / stats.contacted.total).toFixed(0))
      : 0;
    stats.completed.rate = stats.contacted.total
      ? Number((stats.completed.total * 100 / stats.contacted.total).toFixed(0))
      : 0;

    let y = 0;
    for (let i = 0; i < stats.followups.length; i += 1) {
      if (typeof stats.followups[i] !== 'undefined') {
        if (y === 0) {
          stats.followups[i].rate = Number((stats.followups[i].total * 100 / stats.contacted.total).toFixed(0));
        } else {
          stats.followups[i].rate = Number((stats.followups[i].total * 100
            / (stats.followups[y].left + stats.followups[i].total)).toFixed(0));
        }
        y = i;
      }
    }

    this.setState({
      recipients, stats, loading: false,
    });
  }

  search = (keywords) => {
    const { list } = this.state;
    const recipients = {
      scheduled: [],
      contacted: [],
      completed: [],
      followups: [],
      replied: [],
    };
    const stats = {
      scheduled: {
        total: 0,
      },
      completed: {
        total: 0,
      },
      contacted: {
        total: 0,
        rate: 0,
      },
      followups: [],
      replied: {
        total: 0,
        rate: 0,
      },
    };

    if (!keywords || keywords === '') {
      this.sortDataByColumn(list);
    } else {
      for (let i = 0; i < list.length; i += 1) {
        if (list[i].name.toLowerCase().includes(keywords.toLowerCase())
          || list[i].connectionName.toLowerCase().includes(keywords.toLowerCase())) {
          let completed = 0;
          stats.scheduled.total += 1;

          if (list[i].toSend === 0) {
            // completed
            recipients.completed.push(list[i]);
            stats.completed.total += 1;
            completed = 1;
          }
          if (list[i].sent === 0) {
            // scheduled
            if (!completed) {
              recipients.scheduled.push(list[i]);
            }
          } else {
            stats.contacted.total += 1;

            if (typeof stats.followups[list[i].sent - 2] === typeof undefined && list[i].sent - 2 >= 0) {
              stats.followups[list[i].sent - 2] = {
                total: 0,
                rate: 0,
                left: 0,
              };
            }

            // follow ups
            if (!completed) {
              if (typeof recipients.followups[list[i].sent - 1] === typeof undefined) {
                recipients.followups[list[i].sent - 1] = [];
              }
              recipients.followups[list[i].sent - 1].push(list[i]);
            }

            if (list[i].sent > 1) {
              // followups
              let x = 2;
              while (list[i].sent - x >= 0) {
                if (typeof stats.followups[list[i].sent - x] === typeof undefined && list[i].sent - x >= 0) {
                  stats.followups[list[i].sent - x] = {
                    total: 0,
                    rate: 0,
                    left: 0,
                  };
                }
                stats.followups[list[i].sent - x].total += 1;
                x += 1;
              }
              if (list[i].toSend > 0 && list[i].sent - 2 >= 0) {
                stats.followups[list[i].sent - 2].left += 1;
              }
            }

            if (list[i].replied >= 1) {
              // replied
              recipients.replied.push(list[i]);
              stats.replied.total += 1;
            }
          }

          stats.contacted.rate = stats.scheduled.total > 0
            ? Number((stats.contacted.total * 100 / stats.scheduled.total).toFixed(0))
            : 0;
          stats.replied.rate = stats.contacted.total
            ? Number((stats.replied.total * 100 / stats.contacted.total).toFixed(0))
            : 0;
          stats.completed.rate = stats.contacted.total
            ? Number((stats.completed.total * 100 / stats.contacted.total).toFixed(0))
            : 0;

          let y = 0;
          for (let j = 0; j < stats.followups.length; j += 1) {
            if (typeof stats.followups[j] !== 'undefined') {
              if (y === 0) {
                stats.followups[j].rate = Number((stats.followups[j].total * 100 / stats.contacted.total).toFixed(0));
              } else {
                stats.followups[j].rate = Number((stats.followups[j].total * 100
                  / (stats.followups[y].left + stats.followups[j].total)).toFixed(0));
              }
              y = i;
            }
          }
        }
      }

      this.setState({ recipients, stats });
    }
  }

  showNotif = message => showNotification({
    notification: <FullWideNotification
      color="danger"
      message={message}
    />,
    position: 'full',
  }, 'ltr');

  showNotifSuccess = message => showNotification({
    notification: <BasicNotification
      color="primary"
      title="Success!"
      message={message}
    />,
    position: 'right-up',
  });

  showNotifWarning = (message, link) => showNotification({
    notification: <LinkNotification
      color="warning"
      title="Warning!"
      link={link}
      message={message}
    />,
    position: 'right-up',
  });

  render() {
    const {
      recipients, stats, loading,
    } = this.state;
    const { modal } = this.props;

    return (
      <div>
        {!modal && (
          <AccountWarnings
            showModalTrial
          />
        )}
        <SearchElement
          dataOperations={keywords => this.search(keywords)}
        />
        {loading ? (
          <Card className="container crm-container">
            <Row>
              <Col xs={12}>
                Loading ...
              </Col>
            </Row>
          </Card>
        ) : (
          <ColumnsElement
            investors={recipients}
            stats={stats}
          />
        )}
      </div>
    );
  }
}


export default DashboardElements;
