import { Button } from 'reactstrap';
import EditIcon from 'mdi-react/EditIcon';
import React from 'react';
import PropTypes from 'prop-types';

const ButtonsElements = ({ askHowManyConnections, toggleTemplates }) => (
  <div className="mt-5">
    <Button
      color="purple"
      type="button"
      className="rounded icon icon--left float-left ml-5"
      onClick={() => toggleTemplates()}
    >
      <p>
        <EditIcon />&nbsp;Linkedin Campaign
      </p>
    </Button>
    <Button
      color="primary"
      type="button"
      className="icon rounded btn-choose"
      onClick={() => askHowManyConnections()}
    >
      <p>
        Choose for me
      </p>
    </Button>
  </div>
);

ButtonsElements.propTypes = {
  askHowManyConnections: PropTypes.func.isRequired,
  toggleTemplates: PropTypes.func.isRequired,
};

export default ButtonsElements;
