import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import axios from 'axios';

import LinkedinTemplates from './LinkedinTemplates';
import DisplayStaticPage from './DisplayStaticPage';
import SelectConnections from './SelectConnections';

class WizardFormTwo extends PureComponent {
  constructor() {
    super();
    this.state = {
      user: JSON.parse(localStorage.getItem('user')),
      templateCreation: false,
      inProcess: false,
    };
  }

  componentDidMount() {
    this.initPluginEventListener();
  }

  initPluginEventListener = () => {
    const { showNotifSuccess, showNotifError } = this.props;
    const { user, inProcess } = this.state;

    // Listen to event from the plugin:
    document.addEventListener('Plugin', () => {
      // Make sure this event has not yet been triggered
      if (!user.plugin && !inProcess) {
        let updatedUser = null;
        const plugin = 1;
        this.setState({ inProcess: true });

        axios.post('user/plugin', {})
          .then(() => {
            // confirm with the user everyting is ok
            showNotifSuccess('The Plugin has been successfully setup!');

            // Update the DOM & State
            updatedUser = {
              ...user,
              plugin,
            };
            this.setState({
              user: updatedUser,
              inProcess: false,
            });
          }).catch((error) => {
            if (error && error.response) {
              showNotifError(error.response.data.message);
            } else {
              showNotifError('Sorry, an error occurred, please try again.');
            }
          });
      }
    });
  }

  shouldBlockNavigation = () => !!window.location.pathname.startsWith('/campaigns');

  doWeHaveProspectsToDisplay = () => {
    const { prospects } = this.props;
    const { user } = this.state;

    return !!prospects.some(
      prospect => prospect.searched
        && prospect.investorConnections.length > 0
        && !prospect.introduced,
    ) && user.plugin;
  };

  toggleTemplates = () => {
    const { templateCreation } = this.state;
    this.setState({ templateCreation: !templateCreation });
  }

  render = () => {
    const {
      previousPage, prospects, nextPage, actionLeft,
      getInvestorProspects, showNotifError, showNotifSuccess,
    } = this.props;
    const { templateCreation } = this.state;

    return (
      <div>
        {this.doWeHaveProspectsToDisplay() ? (
          <div className="wizard__form wizard__form__step-two">
            {templateCreation
              ? (
                <LinkedinTemplates
                  previousPage={previousPage}
                  showNotifSuccess={showNotifSuccess}
                  showNotifError={showNotifError}
                  getInvestorProspects={getInvestorProspects}
                  cancel={this.toggleTemplates}
                />
              ) : (
                <SelectConnections
                  prospects={prospects}
                  toggleTemplates={this.toggleTemplates}
                  getInvestorProspects={getInvestorProspects}
                  showNotifError={showNotifError}
                  showNotifSuccess={showNotifSuccess}
                  previousPage={previousPage}
                  nextPage={nextPage}
                />
              )}
          </div>
        ) : (
          <DisplayStaticPage
            prospects={prospects}
            previousPage={previousPage}
            nextPage={nextPage}
            getInvestorProspects={getInvestorProspects}
            actionLeft={actionLeft}
          />
        )}
      </div>
    );
  }
}

WizardFormTwo.propTypes = {
  previousPage: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  showNotifSuccess: PropTypes.func.isRequired,
  showNotifError: PropTypes.func.isRequired,
  actionLeft: PropTypes.bool.isRequired,
  prospects: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    investor: PropTypes.shape({
      id: PropTypes.number,
      contactInformation: PropTypes.shape({
        id: PropTypes.number,
        fullName: PropTypes.string,
        picture: PropTypes.shape({
          id: PropTypes.number,
          pictureName: PropTypes.string,
        }),
      }),
    }),
    investorConnections: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      error: PropTypes.bool,
      name: PropTypes.string,
      linkedinUrl: PropTypes.string,
      selected: PropTypes.bool,
      picture: PropTypes.shape({
        id: PropTypes.number,
        linkedinIntroPictureName: PropTypes.string,
      }),
    })),
    searched: PropTypes.bool,
    introduced: PropTypes.bool,
  })).isRequired,
  getInvestorProspects: PropTypes.func.isRequired,
};


export default WizardFormTwo;
