import { Button } from 'reactstrap';
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';
import ChevronRight from 'mdi-react/ChevronRightIcon';
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import StatElements from '../StatElements';
import PageInstallPlugin from './PageInstallPlugin';
import PageRunPluginSearch from './PageRunPluginSearch';
import PageRunPluginMessage from './PageRunPluginMessage';
import PageRefillPlugin from './PageRefillPlugin';

class DisplayStaticPage extends PureComponent {
  constructor(props) {
    super(props);
    this.checkPlugin = null;
  }

  componentDidMount() {
    const { getInvestorProspects } = this.props;
    this.checkPlugin = setInterval(() => getInvestorProspects(true), 7500);
  }

  componentWillUnmount() {
    clearInterval(this.checkPlugin);
  }

  displayStaticPage = (prospects, user) => {
    const { actionLeft } = this.props;
    if (prospects.length > 0 && !user.plugin) {
      return <PageInstallPlugin user={user} />;
    }
    if (prospects.length > 0 && actionLeft && prospects.some(prospect => !prospect.searched)) {
      return <PageRunPluginSearch />;
    }
    if (prospects.length > 0 && actionLeft) {
      return <PageRunPluginMessage />;
    }
    if (prospects.length > 0 && prospects.filter(prospect => !prospect.searched).length === 0) {
      return <PageRefillPlugin />;
    }
    return 'An error occurred, please try again or contact support.';
  };

  render = () => {
    const { prospects, previousPage, nextPage } = this.props;
    const user = JSON.parse(localStorage.getItem('user'));

    return (
      <div className="mt-4 pt-4">
        <StatElements prospects={prospects} />
        {this.displayStaticPage(prospects, user)}
        <div className="w-100 mt-5">
          <Button
            color="primary"
            type="button"
            className="icon rounded icon--left float-left ml-5"
            onClick={previousPage}
          >
            <p>
              <ChevronLeftIcon />&nbsp;Select More Investors
            </p>
          </Button>
          {prospects.length > 0 && prospects.filter(prospect => prospect.searched
              && prospect.introduced).length > 0
            && (
              <Button
                type="button"
                className="icon rounded icon--right float-right mr-5"
                color="primary"
                onClick={nextPage}
              >
                Review Progress <ChevronRight />
              </Button>
            )}
        </div>
      </div>
    );
  }
}

DisplayStaticPage.propTypes = {
  prospects: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    investor: PropTypes.shape({
      id: PropTypes.number,
      contactInformation: PropTypes.shape({
        id: PropTypes.number,
        fullName: PropTypes.string,
        picture: PropTypes.shape({
          id: PropTypes.number,
          pictureName: PropTypes.string,
        }),
      }),
    }),
    investorConnections: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      error: PropTypes.bool,
      name: PropTypes.string,
      linkedinUrl: PropTypes.string,
      selected: PropTypes.bool,
      picture: PropTypes.shape({
        id: PropTypes.number,
        linkedinIntroPictureName: PropTypes.string,
      }),
    })),
    searched: PropTypes.bool,
    introduced: PropTypes.bool,
  })).isRequired,
  previousPage: PropTypes.func.isRequired,
  nextPage: PropTypes.func.isRequired,
  getInvestorProspects: PropTypes.func.isRequired,
  actionLeft: PropTypes.bool.isRequired,
};

export default DisplayStaticPage;
